import styles from './SCC.scss';
export class Start {
  constructor(elementStyles, elementContents) {
    this.elementStyles = elementStyles;
    this.elementContents = elementContents;
    this.cookieSettingModalElement = null;
    this.cookieSettingModalOpenCallback = null;
    this.cookieSettingModalCloseCallback = null;
  }

  static setCookie(key, value, expiration, path = '/') {
    let now;
    if (!expiration) {
      now = new Date();
      now.setMonth(now.getMonth() + 6);
      now.toUTCString();
    }
    document.cookie = `${key}=${value}; expires=${now}; path=${path}`;
  }

  #getCookie(item) {
    let cookie = document.cookie
      .split(';')
      .map((m) => m.trim())
      .filter(
        (f) =>
          f.substring(0, f.indexOf('=')).trim().toLowerCase() ===
          item.toLowerCase()
      );

    if (cookie.length === 0) return false;
    else return cookie[0].substring(cookie[0].indexOf('=') + 1);
  }

  #cookieExist(item = null) {
    let cookie = this.#getCookie(item);
    return cookie === false ? false : true;
  }

  #shouldShowPopup() {
    return !this.#cookieExist('SCC');
  }

  #setStyles(modalAcceptBtn, modalRejectBtn, elementStyles) {
    // Create style element
    let ccStyleElement = document.createElement('style');
    ccStyleElement.setAttribute('id', 'ccStyle');
    document.head.appendChild(ccStyleElement);
    // All Accordions
    try{
    let accordionsStyle = '';
    for (let [key, value] of Object.entries(
      elementStyles.customize['accordion:hover']
    )) {
      accordionsStyle += `${this.Kebabize(key)}:${value} !important;`;
    }
    let accordionsHoverCssStyle = `.${styles['accordion']}:hover { ${accordionsStyle} }`;
    document
      .getElementById('ccStyle')
      .appendChild(document.createTextNode(accordionsHoverCssStyle));
    }catch(ex){
      console.log("Error: 'accordion:hover' doesn't exist!");
    }
    // Primary Button Hover Styles
    let primaryBtnCssStyle = '';
    for (let [key, value] of Object.entries(
      elementStyles.mainDiv.mainButtonsDiv['primaryBtn:hover']
    )) {
      primaryBtnCssStyle += `${this.Kebabize(key)}:${value} !important;`;
    }
    let primaryButtonHoverCssStyle = `.${styles['primaryBtn']}:hover { ${primaryBtnCssStyle} }`;
    document
      .getElementById('ccStyle')
      .appendChild(document.createTextNode(primaryButtonHoverCssStyle));
    // Secondary Button Hover Styles
    let secondaryBtnCssStyle = '';
    for (let [key, value] of Object.entries(
      elementStyles.mainDiv.mainButtonsDiv['secondaryBtn:hover']
    )) {
      secondaryBtnCssStyle += `${this.Kebabize(key)}:${value} !important;`;
    }
    let secondaryButtonHoverCssStyle = `.${styles['secondaryBtn']}:hover { ${secondaryBtnCssStyle} }`;
    document
      .getElementsByTagName('style')[0]
      .appendChild(document.createTextNode(secondaryButtonHoverCssStyle));
    // Close Button Hover Styles
    let closeButtonHoverCssStyle = `.${styles['closeButton']}:hover { background-color:${elementStyles.customize['closeButton:hover']['backgroundColor']} !important; }`;
    document
      .getElementsByTagName('style')[0]
      .appendChild(document.createTextNode(closeButtonHoverCssStyle));
    // Accordion Checkbox
    let labelsCheckedHoverStyles = `.${styles['inputCheckbox']}:checked + label { background-color: ${elementStyles.customize['accordionCheckbox:checked']['backgroundColor']} !important;}`;
    let labelsDisabledHoverStyles = `.${styles['inputCheckbox']}:disabled + label { background-color: ${elementStyles.customize['accordionCheckbox:disabled']['backgroundColor']} !important; cursor: not-allowed !important;}`;
    document
      .getElementsByTagName('style')[0]
      .append(
        document.createTextNode(labelsCheckedHoverStyles),
        document.createTextNode(labelsDisabledHoverStyles)
      );
    // Modal Buttons
    for (const property in elementStyles.mainDiv.mainButtonsDiv.primaryBtn) {
      modalAcceptBtn.style[property] =
        elementStyles.mainDiv.mainButtonsDiv.primaryBtn[property];
    }
    for (const property in elementStyles.mainDiv.mainButtonsDiv.secondaryBtn) {
      modalRejectBtn.style[property] =
        elementStyles.mainDiv.mainButtonsDiv.secondaryBtn[property];
    }
  }

  Kebabize(str) {
    return str.replace(
      /[A-Z]+(?![a-z])|[A-Z]/g,
      ($, ofs) => (ofs ? '-' : '') + $.toLowerCase()
    );
  }

  #setContents(
    mainTextDivTitle,
    mainTextDivDescription,
    mainTextDivCustomizeBtn,
    mainAcceptBtn,
    mainRejectBtn,
    modalContentHeader,
    modalContentTitle,
    modalContentDesc,
    modalAcceptBtn,
    modalRejectBtn,
    elementContents
  ) {
    mainTextDivTitle.innerHTML = elementContents.main.title;
    mainTextDivDescription.innerHTML = elementContents.main.description;
    mainTextDivCustomizeBtn.innerHTML = elementContents.main.customize;
    mainAcceptBtn.innerHTML = elementContents.main.actions.acceptAll;
    mainRejectBtn.innerHTML = elementContents.main.actions.rejectAll;
    modalContentHeader.innerHTML = elementContents.customize.headerTitle;
    modalContentTitle.innerHTML = elementContents.customize.title;
    modalContentDesc.innerHTML = elementContents.customize.description;
    modalAcceptBtn.innerHTML = elementContents.customize.actions.acceptAll;
    modalRejectBtn.innerHTML = elementContents.customize.actions.rejectAll;
  }

  static gtag() {
    dataLayer.push(arguments);
  }

  injectGtagCodeToPage() {
    let scriptElement = document.createElement('script');
    scriptElement.innerText = this.elementContents.scripts.gtag;
    document.head.appendChild(scriptElement);
  }

  loadStartupScript() {
    let consentChecks = this.constructor.fetchAllConsentChecks();
    window.dataLayer = window.dataLayer || [];
    if (localStorage.getItem('consentMode') === null) {
      this.constructor.gtag('consent', 'default', consentChecks);
    } else {
      this.constructor.gtag(
        'consent',
        'default',
        JSON.parse(localStorage.getItem('consentMode'))
      );
    }
  }

  createElement(
    elementType,
    elementName,
    elementID = false,
    attributeList = []
  ) {
    const element = document.createElement(elementType);
    if (elementID === true) element.id = styles[elementName];
    else if (typeof elementID === 'string') element.id = elementID;
    element.classList.add(styles[elementName]);
    for (let i = 0; i < attributeList.length; i++) {
      element.setAttribute(attributeList[i].name, attributeList[i].value);
    }
    return element;
  }

  handleModalShowHide(modalCloseButton) {
    let cookieSettingElements = document.querySelectorAll(
      '[data-cookie-consent-setting]'
    );
    for (let i = 0; i < cookieSettingElements.length; i++) {
      cookieSettingElements[i].addEventListener('click', () => {
        this.cookieSettingModalElement.style.opacity = 1;
        this.cookieSettingModalElement.style.visibility = 'visible';
        this.cookieSettingModalElement.firstElementChild.style.top = '0%';
        this.cookieSettingModalOpenCallback?.();
      });
    }
    window.addEventListener('click', (event) => {
      if ((event.target, event.target === this.cookieSettingModalElement)) {
        this.hideModal();
      }
    });
    modalCloseButton.addEventListener('click', () => {
      this.hideModal();
    });
  }

  handleAccordions(accordion) {
    accordion.addEventListener('click', function (event) {
      if (
        event.target.nodeName !== 'INPUT' &&
        event.target.nodeName !== 'LABEL'
      ) {
        this.classList.toggle(styles.active);
        this.firstElementChild.classList.toggle(styles.arrowReverse);
        var panel = this.nextElementSibling;
        if (panel.style.display === 'block') {
          panel.style.display = 'none';
        } else {
          panel.style.display = 'block';
        }
      }
    });
  }

  static fetchAllConsentChecks() {
    let labels = document.querySelectorAll(
      `.${styles['modal']} [id*="switch"]`
    );
    let childLabel = Array.from(labels).filter((f) => /[_]{1}/.test(f.id));
    let consentChecksObject = {};
    for (let i = 0; i < childLabel.length; i++) {
      let childLabelConsentChecksArray = [];
      if (childLabel[i].getAttribute('data-cc-consent-checks') !== '') {
        childLabelConsentChecksArray = childLabel[i]
          .getAttribute('data-cc-consent-checks')
          .split(',');
        let childLabelConsentChecksStatus = childLabel[i].checked
          ? 'granted'
          : 'denied';
        for (let j = 0; j < childLabelConsentChecksArray.length; j++) {
          consentChecksObject[`${childLabelConsentChecksArray[j]}`] =
            childLabelConsentChecksStatus;
        }
      }
    }
    return consentChecksObject;
  }

  static checkAlllabels() {
    let allCheckbox = document.querySelectorAll(
      `.${styles['modal']} [id^=switch]`
    );
    for (let i = 0; i < allCheckbox.length; i++) {
      if (!allCheckbox[i].disabled) allCheckbox[i].checked = true;
    }
  }

  static uncheckAlllabels() {
    let allCheckbox = document.querySelectorAll(
      `.${styles['modal']} [id^=switch]`
    );
    for (let i = 0; i < allCheckbox.length; i++) {
      if (!allCheckbox[i].disabled) allCheckbox[i].checked = false;
    }
  }

  checkParentLabelToBeChecked() {
    let allCheckbox = document.querySelectorAll(
      `.${styles['modal']} [id^=switch]`
    );
    for (let i = 0; i < allCheckbox.length; i++) {
      let { id } = allCheckbox[i];
      if (!/[_]{1}/.test(id)) {
        let children = document.querySelectorAll(
          `.${styles['modal']} [id*="${id}_"]`
        );
        let allChildrenUnchecked = true;
        for (let i = 0; i < children.length; i++) {
          if (children[i].checked) allChildrenUnchecked = false;
        }
        if (allChildrenUnchecked) {
          allCheckbox[i].checked = false;
        } else {
          allCheckbox[i].checked = true;
        }
      }
    }
  }

  handleLabelClick(label) {
    document.querySelector(
      `.${styles['modal']} #${label.getAttribute('for')}`
    ).onchange = function () {
      let { id, checked } = this;
      if (!/[_]{1}/.test(id)) {
        let childLabels = document.querySelectorAll(
          `.${styles['modal']} [id*="${id}_"]`
        );
        for (let i = 0; i < childLabels.length; i++) {
          if (checked) {
            childLabels[i].checked = true;
          } else {
            childLabels[i].checked = false;
          }
        }
      } else {
        let siblings = document.querySelectorAll(
          `.${styles['modal']} [id*="${id.substring(0, id.indexOf('_'))}_"]`
        );
        let allSiblingsUnchecked = true;
        for (let i = 0; i < siblings.length; i++) {
          if (siblings[i].checked) allSiblingsUnchecked = false;
        }
        if (allSiblingsUnchecked) {
          document.querySelector(
            `.${styles['modal']} #${id.substring(0, id.indexOf('_'))}`
          ).checked = false;
        } else {
          document.querySelector(
            `.${styles['modal']} #${id.substring(0, id.indexOf('_'))}`
          ).checked = true;
        }
      }
    };
  }

  static hideCC() {
    let mainContainer = document.querySelector(
      "[class*='SCC__mainContainer--']"
    );
    mainContainer.style.opacity = 0;
    mainContainer.style.visibility = 'hidden';
  }

  hideModal() {
    this.cookieSettingModalElement.style.opacity = 0;
    this.cookieSettingModalElement.style.visibility = 'hidden';
    this.cookieSettingModalElement.firstElementChild.style.top = '-5%';
    this.cookieSettingModalCloseCallback?.();
  }

  static showCC() {
    let mainContainer = document.querySelector(
      "[class*='SCC__mainContainer--']"
    );
    mainContainer.style.visibility = 'visible';
    mainContainer.style.opacity = 1;
  }

  handleAcceptButton() {
    let consentMode = Start.fetchAllConsentChecks();
    // convert all consent checks status to true
    for (const [key, value] of Object.entries(consentMode)) {
      consentMode[key] = 'granted';
    }
    Start.gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    // set CC
    Start.setCookie('SCC', JSON.stringify(consentMode));
    // hide CC and Modal
    Start.hideCC();
    this.hideModal();
    // check all labels
    Start.checkAlllabels();
  }

  handleRejectButton() {
    let consentMode = Start.fetchAllConsentChecks();
    // convert all consent checks status to true
    for (const [key, value] of Object.entries(consentMode)) {
      consentMode[key] = 'denied';
    }
    Start.gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    // set CC
    Start.setCookie('SCC', JSON.stringify(consentMode));
    // hide CC and Modal
    Start.hideCC();
    this.hideModal();
    // uncheck all labels
    Start.uncheckAlllabels();
  }

  handleSaveSettingButton() {
    let consentMode = Start.fetchAllConsentChecks();
    Start.gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
    // set CC
    Start.setCookie('SCC', JSON.stringify(consentMode));
    // hide CC and Modal
    Start.hideCC();
    this.hideModal();
  }

  run() {
    window.addEventListener('DOMContentLoaded', () => {
      // mainContainer
      const mainContainer = this.createElement('div', 'mainContainer', true);
      document.body.appendChild(mainContainer);
      Object.assign(mainContainer.style, elementStyles.mainContainer);
      // mainDiv
      const mainDiv = this.createElement('div', 'mainDiv');
      mainContainer.appendChild(mainDiv);
      Object.assign(mainDiv.style, elementStyles.mainDiv);
      // mainTextDiv
      const mainTextDiv = this.createElement('div', 'mainTextDiv');
      mainDiv.append(mainTextDiv);
      // mainTextDivTitle
      const mainTextDivTitle = this.createElement('div', 'mainTextDivTitle');
      mainTextDiv.appendChild(mainTextDivTitle);
      Object.assign(
        mainTextDivTitle.style,
        elementStyles.mainDiv.mainTextDiv.mainHeadlines
      );
      // mainTextDivDescription
      const mainTextDivDescription = this.createElement(
        'div',
        'mainTextDivDescription'
      );
      mainTextDiv.appendChild(mainTextDivDescription);
      Object.assign(
        mainTextDivDescription.style,
        elementStyles.mainDiv.mainTextDiv.mainContent
      );
      // mainTextDivCustomize
      const mainTextDivCustomize = this.createElement(
        'div',
        'mainTextDivCustomize'
      );
      Object.assign(
        mainTextDivCustomize.style,
        elementStyles.mainDiv.mainTextDiv.customize
      );
      const mainTextDivCustomizeBtn = this.createElement(
        'a',
        'mainTextDivCustomizeBtn'
      );
      mainTextDivCustomizeBtn.setAttribute(
        'data-cookie-consent-setting',
        'true'
      );
      mainTextDivCustomize.appendChild(mainTextDivCustomizeBtn);
      mainTextDiv.appendChild(mainTextDivCustomize);
      // mainButtonsDiv and Buttons
      const mainButtonsDiv = this.createElement('div', 'mainButtonsDiv');
      const mainAcceptBtn = this.createElement('button', 'primaryBtn');
      mainAcceptBtn.onclick = this.handleAcceptButton.bind(this);
      Object.assign(
        mainAcceptBtn.style,
        elementStyles.mainDiv.mainButtonsDiv.primaryBtn
      );
      const mainRejectBtn = this.createElement('button', 'secondaryBtn');
      mainRejectBtn.onclick = this.handleRejectButton.bind(this);
      Object.assign(
        mainRejectBtn.style,
        elementStyles.mainDiv.mainButtonsDiv.secondaryBtn
      );
      mainDiv.appendChild(mainButtonsDiv);
      mainButtonsDiv.appendChild(mainAcceptBtn);
      mainButtonsDiv.appendChild(mainRejectBtn);
      // modal
      const modal = this.createElement('div', 'modal', true);
      document.body.appendChild(modal);
      this.cookieSettingModalElement = modal;
      // modalContentWrapper
      const modalContentWrapper = this.createElement(
        'div',
        'modalContentWrapper'
      );
      Object.assign(modalContentWrapper.style, elementStyles.customize.modal);
      modal.appendChild(modalContentWrapper);
      // modalContentHeader
      const modalContentHeader = this.createElement(
        'div',
        'modalContentHeader'
      );
      modalContentWrapper.appendChild(modalContentHeader);
      // modalContentHeaderTitle
      const modalContentHeaderTitle = this.createElement(
        'div',
        'modalContentHeaderTitle'
      );
      modalContentHeader.appendChild(modalContentHeaderTitle);
      Object.assign(
        modalContentHeaderTitle.style,
        elementStyles.customize.mainHeadlines
      );
      // Close Button
      const modalCloseButton = this.createElement('span', 'closeButton');
      modalCloseButton.innerHTML = '&times;';
      modalContentHeader.appendChild(modalCloseButton);
      Object.assign(
        modalCloseButton.style,
        elementStyles.customize.closeButton
      );
      // handle modal show/hide
      this.handleModalShowHide(modalCloseButton);
      // modalContent
      const modalContent = this.createElement('div', 'modalContent');
      modalContentWrapper.appendChild(modalContent);
      // modalContentTitle
      const modalContentTitle = this.createElement('div', 'modalContentTitle');
      modalContent.appendChild(modalContentTitle);
      Object.assign(
        modalContentTitle.style,
        elementStyles.customize.contentHeadlines
      );
      // modalContentDesc
      const modalContentDesc = this.createElement('div', 'modalContentDesc');
      modalContent.appendChild(modalContentDesc);
      Object.assign(
        modalContentDesc.style,
        elementStyles.customize.mainContent
      );
      // categories
      for (
        let i = 0;
        i < this.elementContents.customize.categories.length;
        i++
      ) {
        // accordionWrapper
        const accordionWrapper = this.createElement('div', 'accordionWrapper');
        modalContent.appendChild(accordionWrapper);
        // accordion
        const accordion = this.createElement('div', 'accordion');
        Object.assign(accordion.style, elementStyles.customize.accordion);
        accordionWrapper.appendChild(accordion);
        // arrow
        const arrow = this.createElement('div', 'arrow');
        accordion.appendChild(arrow);
        Object.assign(arrow.style, elementStyles.customize.accordionArrow);
        // accordionTitle
        const accordionTitle = this.createElement('div', 'accordionTitle');
        accordion.appendChild(accordionTitle);
        accordionTitle.innerHTML =
          this.elementContents.customize.categories[i].name;
        Object.assign(
          accordionTitle.style,
          elementStyles.customize.contentHeadlines
        );
        // input
        const inputCheckbox = this.createElement(
          'input',
          'inputCheckbox',
          `switch${i}`,
          [
            { name: 'type', value: 'checkbox' },
            {
              name: `${
                this.elementContents.customize.categories[i].disabled === true
                  ? 'disabled'
                  : 'enabled'
              }`,
              value: '',
            },
            {
              name: `${
                this.elementContents.customize.categories[i].checked === true
                  ? 'checked'
                  : 'unchecked'
              }`,
              value: '',
            },
          ]
        );
        accordion.appendChild(inputCheckbox);
        // label
        const label = this.createElement('label', 'label', undefined, [
          { name: 'for', value: `switch${i}` },
        ]);
        accordion.appendChild(label);
        Object.assign(label.style, elementStyles.customize.accordionCheckbox);
        this.handleLabelClick(label);
        // panel
        const accordionPanel = this.createElement('div', 'accordionPanel');
        Object.assign(accordionPanel.style, elementStyles.customize.accordion);
        accordionWrapper.appendChild(accordionPanel);
        const accordionPanelDesc = this.createElement(
          'div',
          'accordionPanelDesc'
        );
        accordionPanel.appendChild(accordionPanelDesc);
        accordionPanelDesc.innerHTML =
          this.elementContents.customize.categories[i].description;
        Object.assign(
          accordionPanelDesc.style,
          elementStyles.customize.mainContent
        );
        // handleAccordion
        this.handleAccordions(accordion);
        // tableContainer
        for (
          let j = 0;
          j < this.elementContents.customize.categories[i]?.items?.length;
          j++
        ) {
          const tableContainer = this.createElement('div', 'tableContainer');
          accordionPanel.appendChild(tableContainer);
          const table = this.createElement('table', 'table');
          tableContainer.appendChild(table);
          const tbody = this.createElement('tbody', 'tbody');
          table.appendChild(tbody);
          // render first row
          const firstRow = this.createElement('tr', 'tr');
          tbody.appendChild(firstRow);
          const th = this.createElement('th', 'th', false, [
            { name: 'scope', value: 'row' },
          ]);
          Object.assign(th.style, elementStyles.customize.tableHeadlines);
          firstRow.appendChild(th);
          th.innerHTML =
            this.elementContents.customize.categories[i]?.items[
              j
            ].checkboxTitle;
          const td = this.createElement('td', 'td', false);
          firstRow.appendChild(td);
          const tdDiv = this.createElement('tdDiv', 'tdDiv', false);
          td.appendChild(tdDiv);
          // first row input check box
          let checkedStatus =
            this.elementContents.customize.categories[i]?.items[j].checked ===
            true
              ? 'checked'
              : 'unchecked';
          let sccCookie = JSON.parse(this.#getCookie('SCC'));
          if (sccCookie) {
            for (
              let k = 0;
              k <
              this.elementContents.customize.categories[i]?.items[j]
                .consentCheck.length;
              k++
            ) {
              checkedStatus =
                sccCookie[
                  this.elementContents.customize.categories[i]?.items[j]
                    .consentCheck[k]
                ] === 'granted'
                  ? 'checked'
                  : 'unchecked';
            }
          }
          const inputCheckbox = this.createElement(
            'input',
            'inputCheckbox',
            `switch${i}_${j}`,
            [
              { name: 'type', value: 'checkbox' },
              {
                name: `${
                  this.elementContents.customize.categories[i]?.items[j]
                    .disabled === true
                    ? 'disabled'
                    : 'enabled'
                }`,
                value: '',
              },
              {
                name: `${checkedStatus}`,
                value: '',
              },
              {
                name: 'data-cc-consent-checks',
                value:
                  this.elementContents.customize.categories[i]?.items[j]
                    .consentCheck,
              },
            ]
          );
          tdDiv.appendChild(inputCheckbox);
          // label
          const label = this.createElement('label', 'label', undefined, [
            { name: 'for', value: `switch${i}_${j}` },
          ]);
          tdDiv.appendChild(label);
          Object.assign(label.style, elementStyles.customize.accordionCheckbox);

          this.handleLabelClick(label);
          for (
            let k = 0;
            k <
            this.elementContents.customize.categories[i].items[j].tables.length;
            k++
          ) {
            const tr = this.createElement('tr', 'tr');
            tbody.appendChild(tr);
            const th = this.createElement('th', 'th', false, [
              { name: 'scope', value: 'row' },
            ]);
            Object.assign(th.style, elementStyles.customize.tableHeadlines);
            tr.appendChild(th);
            th.innerHTML =
              this.elementContents.customize.categories[i].items[j].tables[
                k
              ].name;
            const td = this.createElement('td', 'td', false);
            Object.assign(td.style, elementStyles.customize.tableContent);
            tr.appendChild(td);
            td.innerHTML =
              this.elementContents.customize.categories[i].items[j].tables[
                k
              ].value;
          }
        }
      }
      this.checkParentLabelToBeChecked();
      // modalButtonsDiv
      const modalButtonsDiv = this.createElement('div', 'modalButtonsDiv');
      modalContentWrapper.appendChild(modalButtonsDiv);
      // modalAcceptBtn
      const modalAcceptBtn = this.createElement('button', 'primaryBtn');
      modalButtonsDiv.appendChild(modalAcceptBtn);
      modalAcceptBtn.onclick = this.handleAcceptButton.bind(this);
      // modalRejectBtn
      const modalSaveSettingButton = this.createElement(
        'button',
        'secondaryBtn'
      );
      modalButtonsDiv.appendChild(modalSaveSettingButton);
      modalSaveSettingButton.onclick = this.handleSaveSettingButton.bind(this);
      // setting styles and contents
      this.#setStyles(
        modalAcceptBtn,
        modalSaveSettingButton,
        this.elementStyles
      );
      this.#setContents(
        mainTextDivTitle,
        mainTextDivDescription,
        mainTextDivCustomizeBtn,
        mainAcceptBtn,
        mainRejectBtn,
        modalContentHeaderTitle,
        modalContentTitle,
        modalContentDesc,
        modalAcceptBtn,
        modalSaveSettingButton,
        this.elementContents
      );
    });
    window.addEventListener('load', () => {
      if (this.#shouldShowPopup()) {
        this.constructor.showCC();
      }
      this.loadStartupScript();
      this.injectGtagCodeToPage();
    });
  }

  onOpenCookieSettings(callback) {
    this.cookieSettingModalOpenCallback = callback;
  }
  onCloseCookieSettings(callback) {
    this.cookieSettingModalCloseCallback = callback;
  }
}
